import Vue from 'vue'
import router from "../../../router";
import store from '../../../store';

import {requestFetch} from '../../config'

export default {
    namespaced: true,
    state: {

    },
    mutations: {


    },
    actions: {
        // LOGIN
        async findCalendar({commit}, {data, appointee_id, startDate, endDate}) {
            const response = await requestFetch(`/api/appointment/all/${data}?startDate=${startDate}&endDate=${endDate}`, 'GET');
            return response.appointments;

        },
        async findCalendarSearch({commit}, {data,search}) {
            const response = await requestFetch(`/api/appointment/all_search/${data}`, 'POST',{search:search});
            return response.appointments;

        },
        async findCalendarPlaces({commit}, {data, appointee_id, startDate, endDate}) {
            const response = await requestFetch(`/api/appointment/places_calendar/${data}?startDate=${startDate}&endDate=${endDate}`, 'POST', {appointee_id:appointee_id});
            return response.appointments;

        },
        async findCalendarStates({commit}, {data, appointee_id,states,startDate,endDate}) {
            const response = await requestFetch(`/api/appointment/places_calendar_states/${data}/${states}?startDate=${startDate}&endDate=${endDate}`, 'POST', {appointee_id:appointee_id});
            return response.appointments;

        },
        async findCalendarPlacesSearch({commit}, {data, appointee_id,search}) {
            const response = await requestFetch(`/api/appointment/places_calendar_search/${data}`, 'POST', {appointee_id:appointee_id,search:search});
            return response.appointments;

        },
        async findAppointment({commit}) {
            const response = await requestFetch(`/api/doctor/calendar/doctor`, 'GET');
            return response.doctors

        },

    },
    getters: {
    }

}
